// components/UserList.jsx
import React from 'react';

const UserList = ({ phoneNumberList, selectedPhoneNumber, setSelectedPhoneNumber, selectedMessageList, connData}) => {

  const handlePhoneNumberClick = (phone, index) => {
    setSelectedPhoneNumber(phone);
    
    
    const seqNos = connData[index].seq_nos;
    console.log(`handlePhoneNumberClick index: ${index} , phone: ${phone} ==> seqNos: ${seqNos}`);
    
    selectedMessageList(seqNos);
  };

  return (
    <div className="user-list">
      <h5 className="user-list-header-name">상담 고객</h5>
      <ul>
        {phoneNumberList.map((phone, index) => (
          <li
            key={index}
            onClick={() => handlePhoneNumberClick(phone, index)}
            
            className={selectedPhoneNumber === phone ? 'selected' : ''}
          >
            {phone}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UserList;