// App.js
import React from 'react';
import WebSocketComponent from './components/WebSocketComponent';  // WebSocketComponent를 import

const App = () => {
  return (
    <div className="App">
      <WebSocketComponent />  {/* WebSocketComponent 렌더링 */}
    </div>
  );
};

export default App;