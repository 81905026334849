// components/WebSocketComponent.jsx
import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import UserList from './UserList';
import ChatBox from './ChatBox';
import TitleBar from './TitleBar';  // TitleBar 컴포넌트 임포트
import '/react/test/src/ChatPage.css';  // 스타일을 위한 CSS 파일


const SERVER = 'http://192.168.0.33:8000'


const WebSocketComponent = () => {
  const [socket, setSocket] = useState(null);  // 소켓 상태 관리
  const [receivedData, setReceivedData] = useState([]);  // 수신받은 데이터를 저장할 상태
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);  // 선택된 전화번호 상태 관리
  const [phoneNumberList, setPhoneNumberList] = useState([]);  // 전화번호 목록 상태 관리
  const [title, setTitle] = useState("");  // 타이틀 상태 관리

  const [connData, setConnData] = useState([]);


  useEffect(() => {
    // http://localhost:8000
    // TODO 로컬의 프로그램에서 전화기 MAC 주소 받아오는 로직 필요. (url : http://localhost:9876/getMacAddr)
    
    const socket = io(SERVER, {
      query: { userId: 'exampleUserId2' }  // 초기 연결을 위한 예시 userId
    });

    setSocket(socket);  // 생성된 소켓을 상태로 설정

    // 웹소켓 연결 완료 시 실행
    socket.on('connection', async (id, connData) => {
      setTitle(id);

      setConnData(connData);
      
      // connData에서 전화번호 추출
      // TODO item 에 담긴 seq_nos 도 전화번호와 같이 관리가 되어야 한다.
      const newPhoneNumbers = connData.map(item => item.caller);
      const newSeqNos       = connData.map(item => item.seq_nos);

      // phoneNumberList 업데이트
      setPhoneNumberList(prevList => {
        const updatedList = [...new Set([...newPhoneNumbers, ...prevList])];
        return updatedList;
      });

      // 첫번째 번호에 매칭되는 통화 데이터 전부 조회
      try {
        await selectMessageList(newSeqNos[0]);
      } catch (error) {
        console.log('에러: ', error);
        setReceivedData([]); // 빈 화면 표시
      }

      // 첫 번째 전화번호를 선택된 번호로 설정 (옵션)
      if (newPhoneNumbers.length > 0 && !selectedPhoneNumber) {
        setSelectedPhoneNumber(newPhoneNumbers[0]);
      }
    });


    // 서버로부터 전화 신호(ACK, BYE)를 받을 때 실행
    socket.on('sceq', async (data) => {
      console.log('sceq:::', data);  // 콘솔에 출력

      /**
       * TODO 
       * [ACK] 신호일 때
       *  1) 리스트에 있는 번호면 해당 번호가 리스트 1열에 올라가고 선택.
       *  2) 리스트에 없는 번호면 해당 번호 추가하고 선택.
       * [BYE] 신호일 때
       */
      
      if (data.sceq === 'ACK') {
        const phoneNumber = data.from;
        const isExistingNumber = phoneNumberList.includes(phoneNumber);

        // 새로운 번호로 전화를 받았을 때
        if (!isExistingNumber) {
          console.log(`새로운 번호 [${phoneNumber}]`);
          setPhoneNumberList(prevList => [phoneNumber, ...prevList]);
          setSelectedPhoneNumber(phoneNumber);
          setReceivedData([]); // 빈 화면 표시
        } 
        // 통화 이력이 있는 번호일 경우
        else {
          console.log(`통화 이력이 있는 번호 [${phoneNumber}]`);
          updatePhoneNumberList(phoneNumber);
          await selectMessageList(phoneNumber);

          try {

          } catch (error) {
            console.log('메시지 리스트 조회 에러:', error)
            setReceivedData([]); // 에러 발생 시 빈 화면 표시
          }
        }
      }
    });


    // 서버로부터 통화 데이터를 받을 때마다 실행
    socket.on('transcribe', (data) => {
      const status = data.result_code;
      console.log('transcribe:::', status);  // 콘솔에 출력
      
      if (status === 201 ) {
        console.log("무음");
        return;
      }
      
      setReceivedData((prevData) => {
        const newData = [...prevData, data];  // 수신 데이터를 상태에 추가
        
        //setTitle(data.type);

        // 새로 수신된 데이터의 전화번호가 이미 목록에 있는 경우 최상단으로 이동
        setPhoneNumberList((prevList) => {
          if (prevList.includes(data.phone_number)) {
            const newList = [data.phone_number, ...prevList.filter(phone => phone !== data.phone_number)];
            return newList;
          }

          return prevList;  // 이미 목록에 없으면 그대로 유지
        });

        return newData;
      });

      updatePhoneNumberList(data.phone_number);
      
      // 데이터 수신 시, 자동으로 해당 전화번호 선택 (옵션)
      setSelectedPhoneNumber(data.phone_number);
    });


    // 서버로부터 통화 기록 리스트 화면 출력
    socket.on('messageList', (list) => {
      console.log('messageList 응답:::',list);
      setReceivedData(list);
    });


    // 컴포넌트가 언마운트될 때 소켓 연결 해제
    return () => {
      socket.disconnect();
    };
  }, []);  // 빈 배열로 설정하여 컴포넌트가 마운트될 때만 실행


  // 번호 리스트 업데이트
  const updatePhoneNumberList = (phoneNumber) => {
    setPhoneNumberList((prevList) => {
      let newList = prevList.filter(phone => phone !== phoneNumber);
      newList.unshift(phoneNumber);
      console.log('newList', newList);
      return newList;
    });

    setSelectedPhoneNumber(phoneNumber);
  };


  // 번호에 매칭되는 통화 기록 조회
  const selectMessageList = async (seqNos) => {
    try{
      console.log('selectMessageList:::', seqNos);

      const jsonData = {
        "userId" : 1,
        "seqNos" : [seqNos]
      };

      const url = 'https://popbio.ai/api/aicc/messageList'

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonData),
      });

      if (!response.ok) {
        throw new Error('네트워크 응답이 올바르지 않습니다.');
      }
      
    } catch (error) {
      console.error('에러:', error);
    }
  };


  // 초기 전화번호 목록 설정
  /*
  useEffect(() => {
    const uniquePhoneNumbers = Array.from(new Set(receivedData.map(data => data.phone_number)));
    
    // 중복 제거 및 병합
    setPhoneNumberList((prevList) => [...new Set([...prevList, ...uniquePhoneNumbers])]); 
  }, [receivedData]);
  */

  
  // 선택된 전화번호에 해당하는 채팅 메시지 필터링
  //const filteredMessages = receivedData.filter(data => data.phone_number === selectedPhoneNumber);
  const filteredMessages = receivedData;
  

  return (
    <div>
      
      {/* TitleBar 컴포넌트를 사용하여 제목 표시 */}
      <TitleBar title={title} />

      <div className="chat-container">
        <UserList
          phoneNumberList={phoneNumberList}
          selectedPhoneNumber={selectedPhoneNumber}
          setSelectedPhoneNumber={setSelectedPhoneNumber}
          selectedMessageList={selectMessageList}
          connData={connData}
        />
        <ChatBox filteredMessages={filteredMessages} />
      </div>

    </div>

    
  );
};

export default WebSocketComponent;