// components/ChatBox.jsx
import React, { useEffect, useRef } from 'react';

const ChatBox = ({ filteredMessages }) => {
  const messagesEndRef = useRef(null);

  // 새로운 메시지가 추가될 때마다 스크롤을 가장 아래로 이동
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [filteredMessages]);

  return (
    <div className="chat-box-container">
      <div className="chat-box">
        <ul className="chat-messages">
          {filteredMessages.map((data, index) => (
            <li
              key={index}
              // left(0) : 고객 , right(1) : 상담사
              className={`chat-message ${data.type === 1 ? 'right' : 'left'}`}
            >
              {JSON.stringify(data.text)}
            </li>
          ))}
          {/* 스크롤을 가장 아래로 내리기 위한 빈 div */}
          <div ref={messagesEndRef} />
        </ul>
      </div>
      {/* 하단 30% 영역 */}
      <div className="chat-footer">
            <ul>상담 요약</ul>
      </div>
    </div>
  );
};

export default ChatBox;